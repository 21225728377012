import request from '../Axios'

// 活动列表
export const activityQuery = data => {
  return request({
    url: '/activity/query',
    method: 'get',
    params: data
  })
}

// 创建活动
export const activityCreate = data => {
  return request({
    url: '/activity/create',
    method: 'post',
    data
  })
}

// 修改活动
export const activityUpdate = data => {
  return request({
    url: '/activity/update',
    method: 'put',
    data
  })
}

// 上下架
export const activityEnable = params => {
  return request({
    url: `/activity/enable/${params.id}`,
    method: 'put'
  })
}

// 修改首页图片
export const activityPicture = data => {
  return request({
    url: '/activity/picture',
    method: 'put',
    data
  })
}

// 删除活动
export const activityDelete = params => {
  return request({
    url: `/activity/delete/${params.id}`,
    method: 'delete'
  })
}

// 查询排序
export const activitySort = params => {
  return request({
    url: `/activity/sort/${params.type}`,
    method: 'get'
  })
}

export const ossObject = () => {
  return request({
    url: '/oss/getObject',
    method: 'get'
  })
}

export const putObject = data => {
  return request({
    url: '/oss/putObject',
    method: 'get',
    params: data
  })
}
